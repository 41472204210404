import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import {
  getDefaultPaymentMethod,
  getPaymentIntentSecret,
  getStripeSetupIntentSecret,
  getUpcomingInvoice,
  getUpcomingInvoiceForPlan,
  updateDefaultPaymentMethod,
} from "../api/PaymentApi";

import { userKeys } from "./keys";

import { IntentType } from "../componentsTS/stripe/intents";
import useUser from "../hooks/useUser";

export enum UpcomingInvoiceAction {
  ADD_LOCATIONS = "ADD_LOCATIONS",
  GET_NUMBER = "GET_NUMBER",
  DOWNGRADE = "DOWNGRADE",
}
// Query to get the setup secret for a stripe intent.
export const useStripeIntentQuery = (userId: string, type: IntentType) => {
  // Get the query key.
  const queryKey = userKeys.stripeIntent({ userId, type });

  // Determine the query function to use.
  const queryFn = type === IntentType.Setup ? getStripeSetupIntentSecret : getPaymentIntentSecret;

  // Setup the query.
  return useQuery(queryKey, queryFn);
};

// Query to get the upcoming invoices for a user.
export const useUpcomingInvoiceQuery = (userId: string, action?: string) => {
  // Get the query key.
  const queryKey = userKeys.upcomingInvoice({ userId, action });

  // Setup the query.
  return useQuery(queryKey, () => getUpcomingInvoice(action));
};

export const useUpcomingInvoiceForPlan = ({ action, planId }: { planId: string; action?: UpcomingInvoiceAction }) => {
  // Get the query key.
  const user = useUser();

  // Get the query key.
  const queryKey = userKeys.upcomingInvoiceForPlan({ userId: user.id, action, planId });

  // Setup the query.
  return useQuery(queryKey, () =>
    getUpcomingInvoiceForPlan({
      action,
      planId,
    })
  );
};

// Query to get the default payment method for a user.
export const usePaymentMethodQuery = (userId: string) => {
  // Get the query key.
  const queryKey = userKeys.paymentMethod({ userId });

  // Setup the query.
  return useQuery(queryKey, getDefaultPaymentMethod);
};

export const useUpdatePaymentMethodMutation = (userId: string) => {
  const queryClient = useQueryClient();

  // Once we successfully modify the data,
  // update the cache with the new payment method.
  const onSuccess = (data: Awaited<ReturnType<typeof updateDefaultPaymentMethod>>) => {
    // Only update the cache if we have a card.
    if (data.data?.card) {
      queryClient.setQueryData(userKeys.paymentMethod({ userId }), data);
    }
  };

  // Mutation options.
  const mutationOpts = { onSuccess };

  // Setup the mutation
  return useMutation(updateDefaultPaymentMethod, mutationOpts);
};
