import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import useUser from "./useUser";
import { SubscribeFlowSource } from "./useSubscribeFlow";
import { newPlans } from "../helpers/newPlanHelper";
import { AgentStore, Role, StoreTypes } from "../app/StoreTypes";

/**
 * Hook to determine where the user should be redirected when they try to add a location.
 *
 * @returns A function that navigates the user to the correct location based on their current plan.
 */
const useAddLocationRedirect = () => {
  const user = useUser();
  const params = useParams();
  const navigate = useNavigate();

  const { availableAgents } = useSelector<StoreTypes, AgentStore>((state) => state.agent);

  const agentsUserOwns = availableAgents.filter((agent) =>
    user.roles.some((role) => role.roleId === Role.OWNER && role.agentId === agent.id)
  );

  const { pendingPlanChange } = useUser();

  // check if any agents are "free" agents
  const freeAgents = agentsUserOwns.filter((agent) => agent.phoneNumber && agent.planName === "solo");

  const { agentId } = params;

  const isFreeUser = freeAgents.length > 0;

  // If we can't find the agentId, redirect to the dashboard.
  if (!agentId) {
    return () => navigate("/dashboard");
  }

  // Construct the root path to our target location.
  const path = `/dashboard/${agentId}`;

  if (pendingPlanChange) {
    return () =>
      navigate(`${path}/subscription/change-plan/pending-subscription-change?returnTo=${window.location.pathname}`);
  }

  // Handle users in demo or solo mode.
  // The "demo" planName is not available in the user object.
  // It is only conceptually a demo when the user is in the solo plan and has no agents with phone numbers.
  if (user.planName === "solo") {
    // If they are wanting to add a location, determine if they are a free user with a number.
    // this is a legacy state, and needs to be handled differently.
    if (isFreeUser) {
      return () => navigate(`${path}/subscription/subscribe-solo/location-limit`);
    }

    // if they are just a demo user without trial consumed, they can go to trial subscription
    if (!user.trialConsumed) {
      return () => navigate(`${path}/subscription/trial`);
    }

    // otherwise, they can go to normal subscribe
    return () => navigate(`${path}/subscription/subscribe?source=${SubscribeFlowSource.DEMO_TRIAL_ADD_LOCATION}`);
  }

  // If the user is currently in a trial, they have to finish the trial before they can add a location.
  if (user.subscriptionStatus === "trialing") {
    return () => navigate(`${path}/subscription/subscribe-trial`);
  }

  // Handle users who have gone over their location limit.
  if (user.planLocationsUsed >= user.planLocationsAllowed) {
    // TODO: Check if this is the correct path.
    return () => navigate(`${path}/subscription/change-plan`);
  }

  if (newPlans.includes(user.planName)) {
    // Otherwise, let the user add a new location.
    return () => navigate(`${path}/subscription/add-location`);
  }

  // Legacy users will need to subscribe to add a location.
  return () => navigate(`${path}/add-new-location`);
};

export default useAddLocationRedirect;
